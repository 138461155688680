/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // INIT Z-INDEX ONCLICK
                $(function () {
                    var zIndex = 1;
                    $(".drag").mousedown(function () {
                        $(this).css('z-index', zIndex++);
                    });
                });
                // END Z-INDEX ONCLICK

                // INIT DRAG
                $(function () {
                    $(".drag").draggable();
                });
                // END DRAG

                // INIT BLURBS ANIMATION
                /*
	Metaballs with help from:
	http://www.somethinghitme.com/2012/06/06/2d-metaballs-with-canvas/
*/


                var canvas = document.getElementById("canvas");
                var ctx = canvas.getContext("2d");

                // Temporary canvas used to allow thresholding of alpha values to control "blobbyness"
                var tempCanvas = document.createElement("canvas");
                var tempCtx = tempCanvas.getContext("2d");

                var width = canvas.width,
                    height = canvas.height;

                tempCanvas.width = width;
                tempCanvas.height = height;

                var particlesArray = [];

                // Threshold for alpha cut-off, smaller numbers increase the size of the blobs but also make them "fuzzier"
                // ~ 200 is a good value 
                var threshold = 200;

                // shim layer with setTimeout fallback - Paul Irish
                window.requestAnimFrame = (function () {
                    return window.requestAnimationFrame ||
                        window.webkitRequestAnimationFrame ||
                        window.mozRequestAnimationFrame ||
                        function (callback) {
                            window.setTimeout(callback, 1000 / 60);
                        };
                })();

                var Particle = function (x, y, vx, vy, radius) {
                    this.position = {
                        x: x,
                        y: y
                    };
                    this.velocity = {
                        x: vx,
                        y: vy
                    };
                    this.radius = radius;

                    // Rainbow blobs
                    this.colour = {
                        red: Math.floor(255 * Math.random()),
                        green: Math.floor(255 * Math.random()),
                        blue: Math.floor(255 * Math.random())
                    };
                };

                var createParticles = function (n) {
                    for (var i = 0; i < n; i++) {
                        // Define new x & y positions and velocities, radius
                        var xPos = Math.random() * canvas.width,
                            yPos = Math.random() * canvas.height,
                            xVel = Math.random() * 4 - 2,
                            yVel = Math.random() * 4 - 2,
                            rad = Math.floor(Math.random() * 30) + 30;

                        // Create a particle with the above values and push to an array	
                        var p = new Particle(xPos, yPos, xVel, yVel, rad);
                        particlesArray.push(p);
                    }
                };

                var render = function () {
                    window.requestAnimFrame(render);
                    // Clear temp canvas
                    tempCtx.clearRect(0, 0, width, height);

                    for (var i = 0; i < particlesArray.length; i++) {
                        var particle = particlesArray[i];

                        // Update positions based on velocity
                        particle.position.x += particle.velocity.x;
                        particle.position.y += particle.velocity.y;

                        // Check if particles are going off-screen, if so then put on other side of canvas to give continuous movement
                        if (particle.position.x > width + particle.radius) {
                            particle.position.x = 0 - particle.radius;
                        }
                        if (particle.position.x < 0 - particle.radius) {
                            particle.position.x = width + particle.radius;
                        }
                        if (particle.position.y > height + particle.radius) {
                            particle.position.y = 0 - particle.radius;
                        }
                        if (particle.position.y < 0 - particle.radius) {
                            particle.position.y = height + particle.radius;
                        }


                        // Update colour positions on temp canvas
                        tempCtx.beginPath();
                        var gradient = tempCtx.createRadialGradient(particle.position.x, particle.position.y, 0, particle.position.x, particle.position.y, particle.radius);
                        gradient.addColorStop(0, 'rgba(' + particle.colour.red + ',' + particle.colour.green + ', ' + particle.colour.blue + ',1)');
                        gradient.addColorStop(1, 'rgba(' + particle.colour.red + ',' + particle.colour.green + ', ' + particle.colour.blue + ',0)');
                        tempCtx.fillStyle = gradient;
                        tempCtx.arc(particle.position.x, particle.position.y, particle.radius, 0, 2 * Math.PI);
                        tempCtx.fill();
                    }
                    // Send to function that takes pixel data and implements alpha cut-off - then draws to the main canvas
                    metaballDraw();

                };


                var metaballDraw = function () {
                    // Get pixel data of temporary canvas, this allows us to check the alpha values
                    var imgData = tempCtx.getImageData(0, 0, width, height);
                    var pixels = imgData.data;

                    //The pixel data is given in r,g,b,a form
                    // So to check the alpha value we must look at the n*4th pixel
                    for (var i = 0; i < pixels.length; i += 4) {
                        if (pixels[i + 3] < threshold) {
                            pixels[i + 3] = 0;
                        }
                    }
                    // Draw final result to the real canvas
                    ctx.putImageData(imgData, 0, 0);
                };

                var init = function () {
                    createParticles(50);
                    render();

                };

                init();
                //END BLURBS ANIMATION

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            },
            // Home page
            'home': {
                init: function () {
                    // JavaScript to be fired on the home page
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS

                }
            },
            'grid': {
                init: function () {
                    // JavaScript to be fired on the home page
                },
                finalize: function () {
                    // JavaScript to be fired on the home page, after the init JS
                }
            },
            // About us page, note the change from about-us to about_us.
            'about_us': {
                init: function () {
                    // JavaScript to be fired on the about us page
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.